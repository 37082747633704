
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" 
         uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card ,.jigyo-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <page-title></page-title>
          <div class="uk-animation-toggle" v-for="(info, pageName, i) of pageNames" :key="i">
            <router-link :to="info.path">
              <div class="jigyo-card " :style="'background-image:url(' + require('@/assets/img/'+ parts[pageName].image)  + ');' " >
                <div class="jigyo-sub-card ">
                  <h3 >{{$_chlang(info.title)}}</h3>
                  <span>
                    {{$_chlang(parts[pageName].subject)}}
                    <span class="uk-hidden@s" >Read more>></span>
                    <br class="uk-visible@s" />
                    <span class="icon-detail uk-visible@s" uk-icon="icon: chevron-double-right;ratio: 2 ">
                      Read more
                    </span>
                  </span>
                </div>
              </div>
            </router-link>
        </div>
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>

export default {

  data: () => ({
    pageNames:{},
    parts:{},
  }),
  created() {
    // メインメニュー作成
    this.pageNames = this.$_menu(this.commonSetting.jigyoMenuList);
    this.parts = this.commonSetting.jigyoMenuPartsList;
  },

}
</script>



<style scoped lang="scss">

.parallax-bg {
  background-image:url(~@/assets/img/bg-image2.jpg);
}
.jigyo-card{
  width: auto;
  height: 30vh;
  margin: 3vh 10vw ;
  border-radius: 10px ;
  background-size: cover;
  background-position: center;
  padding:2%;
  position: relative;
  text-shadow: 2px 2px 2px #000000;
  
  .jigyo-sub-card{
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px ;
    width:100%;
    height: 100%;

    h3{
      color: rgb(255, 255, 255);
      padding:3rem 0 0 3rem ;
      font-size: 2rem;
      font-family: var(--font-family-text) !important;
        @media screen and (max-width: 640px) {
          padding:1rem 0 0 2rem ;
          margin-bottom: 0;
        }
    }
    span{
      padding:1rem 3rem 0 3rem ;
      display:inline-block;
      font-size: 1.1rem;
      white-space: pre-line;
    }
  }

  &:hover {
    .jigyo-sub-card{
      .icon-detail{
 
        animation: poyopoyo 2s ease-out infinite;
        opacity: 1;

          @keyframes poyopoyo {
            0%, 40%, 60%, 80% {
              transform: scale(1);
            }
            50%, 70% {
              transform: scale(0.9);
            }
          }
      }
    }
  }
  .jigyo-sub-card-hover{
    color: rgb(255, 255, 255);
    transition: all .3s;
    border-radius: 10px ;
    width:100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(0, 0, 0, 0.8);

    span{
      position: absolute;
      top: 50%;
      font-size: 1.2rem;
      font-weight: 600 ;
    }
  }
}
</style>
